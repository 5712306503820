import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classNames from 'classnames'
import Img from 'gatsby-image'

import { AppContext } from '_context'
import { Layout } from '_templates'
import { Container, Image, Heading, Text, ScrollArrow } from '_atoms'
import { CarouselSlider, Quote, ProudCases } from '_molecules'
import { SEO, ViewportObserver } from '_organisms'
import LockitronLogo from '_images/svgs/lockitron-logo-colored.inline.svg'
import LockClosed from '_images/svgs/pages/portfolio/lockitron/lock-closed.svg'
import myQ from '_images/pages/portfolio/lockitron/myq.png'
import techcrunch from '_images/pages/portfolio/lockitron/techcrunch.png'
import PaymentIcon from '_images/svgs/payment.inline.svg'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const getSliderOptions = navContainer => {
  return {
    controls: false,
    mouseDrag: true,
    loop: false,
    items: 1.8,
    gap: 0,
    navContainer,
    gutter: 20,
    responsive: {
      480: {
        items: 2.5,
        gutter: 40,
      },
      768: {
        items: 3,
        gutter: 40,
      },
      992: {
        items: 4,
        gutter: 40,
      },
      1280: {
        items: 5,
        gutter: 60,
      },
    },
  }
}

const LockitronCaseStudy = ({ data }) => {
  const lockitronCaseStudy = data.contentfulLockitronCaseStudy
  const { testimonial } = lockitronCaseStudy

  const { setNavbarColor, setNavbarLight } = useContext(AppContext)

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor('transparent')
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  return (
    <Layout navbarColorOverride="transparent">
      <SEO
        title={
          lockitronCaseStudy.pageTitle || 'Web &amp; Mobile App Design and Development Company'
        }
        description={
          lockitronCaseStudy.pageDescription ||
          'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={lockitronCaseStudy.pageThumbnail.file.url}
      />
      <main className={styles.hero}>
        <Container>
          <Row className={styles.heroSectionRow}>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" bottom>
                <LockitronLogo className={styles.heroLogo} />
              </Fade>
              <Fade distance="10%" delay={200} bottom>
                <Text className={styles.heroDescription} splitParagraphs color="white">
                  {lockitronCaseStudy.heroDescription.internal.content}
                </Text>
              </Fade>
              <Fade distance="10%" delay={400} bottom>
                <ScrollArrow className={classNames(styles.arrowButton)} />
              </Fade>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Fade distance="10%" delay={350} bottom>
                <div className={styles.heroCircleWrapper}>
                  <div className={classNames(styles.heroCircle, styles.pulse)}>
                    <div className={styles.scale}>
                      <LockClosed />
                      <Text className={styles.heroCircleText} splitParagraphs color="white">
                        Tap here to unlock
                      </Text>
                    </div>
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor('transparent')
            setNavbarLight(false)
          }}
        />
      </main>
      <section className={styles.knowingProject}>
        <Container>
          <Row className={styles.knowingProjectSectionRow}>
            <Col xs={12} sm={12} md={8} lg={8} className={styles.knowingProjectImageWrapper}>
              <Fade distance="10%" delay={350} bottom>
                <Img
                  fluid={lockitronCaseStudy.knowingProjectImage.fluid}
                  alt={lockitronCaseStudy.knowingProjectImage.description}
                  fadeIn={false}
                />
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={4} lg={4} className={styles.knowingProjectTextWrapper}>
              <Fade distance="10%" bottom>
                <Heading
                  type="h1"
                  color="lockitronAccent"
                  bold
                  className={classNames(styles.heading, styles.knowingProjectHeading)}
                >
                  {lockitronCaseStudy.knowingProjectTitle}
                  <Text color="lockitronSecondary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
              <Fade distance="10%" bottom>
                <Text className={styles.description} color="dark" splitParagraphs>
                  {lockitronCaseStudy.knowingProjectDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lockitron-primary'])
            setNavbarLight(true)
          }}
        />
      </section>
      <section className={styles.newInterface}>
        <Container>
          <Row className={styles.newInterfaceSectionRow}>
            <Col xs={12} sm={12} md={9} lg={9}>
              <Fade distance="10%" bottom>
                <Heading type="h4" color="lockitronAccent" bold className={styles.heading}>
                  {lockitronCaseStudy.newInterfaceTitle}
                  <Text color="lockitronSecondary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text color="dark" className={styles.description} splitParagraphs>
                  {lockitronCaseStudy.newInterfaceDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <div className={styles.newCarouselWrapper}>
          <Fade distance="10%" delay={350} bottom>
            <CarouselSlider
              id="new-interface"
              images={lockitronCaseStudy.newInterfaceImages}
              options={getSliderOptions('#new-interface-nav')}
            />
          </Fade>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.newLook}>
        <Container>
          <div className={styles.newLookContentWrapper}>
            <Fade distance="10%" bottom>
              <Heading type="h4" color="lockitronAccent" bold className={styles.heading}>
                {lockitronCaseStudy.newLookTitle}
                <Text color="lockitronSecondary" className={styles.dot}>
                  .
                </Text>
              </Heading>
            </Fade>

            <Fade distance="10%" delay={200} bottom>
              <Text color="dark" className={styles.description} splitParagraphs>
                {lockitronCaseStudy.newLookDescription.internal.content}
              </Text>
            </Fade>
          </div>
        </Container>

        <div className={styles.newCarouselWrapper}>
          <Fade distance="10%" delay={350} bottom>
            <CarouselSlider
              id="new-look"
              images={lockitronCaseStudy.newLookImages}
              options={getSliderOptions('#new-look-nav')}
            />
          </Fade>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lockitron-primary'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.sharedAccess}>
        <Container>
          <Row className={styles.sharedAccessContentWrapper}>
            <Col xs={12} sm={6} md={6} lg={6} className={styles.sharedAccessTextWrapper}>
              <Fade distance="10%" bottom>
                <Heading type="h4" color="white" bold className={styles.heading}>
                  {lockitronCaseStudy.sharedAccessTitle}
                  <Text color="lockitronPrimary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text className={styles.description} color="white" splitParagraphs>
                  {lockitronCaseStudy.sharedAccessDescription.internal.content}
                </Text>
              </Fade>
            </Col>

            <Col xs={12} sm={6} md={6} lg={6} className={styles.sharedAccessImageWrapper}>
              {lockitronCaseStudy.sharedAccessImages.map((image, index) => (
                <Fade distance="10%" key={image.fluid.src} delay={(index + 2) * 175} bottom>
                  <Image {...image.fluid} alt={image.description} className={styles.sharedImage} />
                </Fade>
              ))}
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lockitron-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.proximity}>
        <Container>
          <Row className={styles.proximityContentWrapper}>
            <Col xs={12} sm={12} md={7} lg={7} className={styles.proximityImageWrapper}>
              <Fade distance="10%" delay={350} bottom>
                <Image
                  {...lockitronCaseStudy.proximityImage.fluid}
                  alt={lockitronCaseStudy.proximityImage.description}
                  className={styles.image}
                />
              </Fade>
            </Col>

            <Col xs={12} sm={12} md={5} lg={5} className={styles.proximityTextWrapper}>
              <Fade distance="10%" bottom>
                <Heading type="h4" color="white" bold className={styles.heading}>
                  {lockitronCaseStudy.proximityTitle}
                  <Text color="lockitronPrimary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>

              <Fade distance="10%" delay={200} bottom>
                <Text className={styles.description} color="white" splitParagraphs>
                  {lockitronCaseStudy.proximityDescription.internal.content}
                </Text>
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lockitron-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.wearables}>
        <Container>
          <Row align="middle">
            <Col span={12} md={6} lg={6}>
              <div className={styles.wearablesContent}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" color="white" bold className={styles.heading}>
                    {lockitronCaseStudy.wearablesTitle}
                    <Text color="lockitronPrimary" className={styles.dot}>
                      .
                    </Text>
                  </Heading>
                </Fade>
                <Fade distance="10%" delay={200} bottom>
                  <Text className={styles.description} color="white" splitParagraphs>
                    {lockitronCaseStudy.wearablesDescription.internal.content}
                  </Text>
                </Fade>
              </div>
            </Col>
            <Col span={12} md={6} lg={6} className={styles.wearablesRight}>
              <Fade distance="10%" delay={350} bottom>
                <Image
                  {...lockitronCaseStudy.wearablesImage.fluid}
                  alt={lockitronCaseStudy.wearablesImage.description}
                  className={styles.wearablesImage}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lockitron-secondary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.realTime}>
        <Container>
          <Row align="middle">
            <Col span={12} md={7} lg={7}>
              <div className={styles.realTimeContent}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" color="lockitronAccent" bold className={styles.heading}>
                    {lockitronCaseStudy.realTimeTitle}
                    <Text color="lockitronSecondary" className={styles.dot}>
                      .
                    </Text>
                  </Heading>
                </Fade>
                <Fade distance="10%" delay={200} bottom>
                  <Text className={styles.description} color="dark" splitParagraphs>
                    {lockitronCaseStudy.realTimeDescription.internal.content}
                  </Text>
                </Fade>
              </div>
            </Col>
            <Col span={12} md={5} lg={5} className={styles.realTimeRight}>
              <Fade distance="10%" delay={350} bottom>
                <Image
                  {...lockitronCaseStudy.realTimeImage.fluid}
                  alt={lockitronCaseStudy.realTimeImage.description}
                  className={styles.realTimeImage}
                />
              </Fade>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lockitron-primary'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.control}>
        <Image
          {...lockitronCaseStudy.controlImage.fluid}
          alt={lockitronCaseStudy.controlImage.description}
          className={styles.controlImage}
        />
        <Image
          {...lockitronCaseStudy.controlImageMobile.fluid}
          alt={lockitronCaseStudy.controlImageMobile.description}
          className={styles.controlImageMobile}
        />

        <div className={styles.controlContent}>
          <Container className={styles.controlContainer}>
            <Row>
              <Col span={12} md={{ span: 6, offset: 6 }} lg={{ span: 6, offset: 6 }}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" color="white" bold className={styles.heading}>
                    {lockitronCaseStudy.controlTitle}
                    <Text color="lockitronSecondary" className={styles.dot}>
                      .
                    </Text>
                  </Heading>
                </Fade>

                <Fade distance="10%" delay={200} bottom>
                  <Text color="white" className={styles.description} splitParagraphs>
                    {lockitronCaseStudy.controlDescription.internal.content}
                  </Text>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lockitron-gray-accent'])
            setNavbarLight(false)
          }}
        />
      </section>

      <section className={styles.testimonial}>
        <Container className={styles.testimonialContentWrapper}>
          <Fade distance="10%" bottom>
            <Quote
              mobileComplete
              color="lockitronSecondary"
              text={testimonial.text.internal.content}
              user={testimonial.user}
              userPhoto={testimonial.userImage.fixed}
              userJob={testimonial.jobDescription}
            />
          </Fade>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-gray-lightest'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.results}>
        <div className={styles.resultsBlock}>
          <Container className={styles.resultsContainer}>
            <div className={styles.resultsItem}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="white" className={styles.resultsHeading}>
                  <FormattedMessage id="casesCommon.results" />
                  <Text color="lockitronPrimary" className={styles.dot}>
                    .
                  </Text>
                </Heading>
              </Fade>
            </div>

            <div className={styles.resultsItem}>
              <Image src={myQ} alt="my Q" className={styles.resultsMyQ} />
              <div className={styles.resultsContent}>
                <Fade distance="10%" bottom>
                  <Text size="18" color="white" className={styles.resultsText}>
                    <FormattedMessage id="lockitron.integration1" />{' '}
                    <span className={styles.resultsHighlight}>Chamberlain Group</span>{' '}
                    <FormattedMessage id="lockitron.integration2" />{' '}
                    <span className={styles.resultsHighlight}>myQ</span>{' '}
                    <FormattedMessage id="lockitron.integration3" />
                  </Text>
                </Fade>
              </div>
            </div>

            <div className={styles.resultsItem}>
              <Image src={techcrunch} alt="Tech Crunch" className={styles.resultsTechCrunch} />
              <div className={styles.resultsContent}>
                <Fade distance="10%" bottom>
                  <Text size="18" color="white" className={styles.resultsText}>
                    <FormattedMessage id="lockitron.featured1" />{' '}
                    <span className={styles.resultsHighlight}>TechCrunch</span>{' '}
                    <FormattedMessage id="lockitron.featured2" />
                  </Text>
                </Fade>

                <Fade distance="10%" bottom delay={200}>
                  <Text size="18" color="white" className={styles.resultsText}>
                    <FormattedMessage id="lockitron.featuredLink1" />{' '}
                    <a
                      href={lockitronCaseStudy.articleLink}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={styles.resultsLink}
                    >
                      <FormattedMessage id="lockitron.featuredLink2" />
                    </a>
                  </Text>
                </Fade>
              </div>
            </div>

            <div className={styles.resultsItem}>
              <PaymentIcon className={styles.resultsPayment} />
              <div className={styles.resultsContent}>
                <Fade distance="10%" bottom>
                  <Text size="18" color="white" className={styles.resultsText}>
                    <FormattedMessage id="lockitron.venture" />{' '}
                    <span className={styles.resultsHighlight}>Andreessen Horowitz.</span>
                  </Text>
                </Fade>
              </div>
            </div>
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['lockitron-accent'])
            setNavbarLight(false)
          }}
        />
      </section>

      <div className={styles.portfolio}>
        <Fade distance="10%" bottom>
          <ProudCases
            currentProject="Lockitron"
            caseStudies={lockitronCaseStudy.proudCases}
            extendedCTA
          />
        </Fade>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query contentfulLockitronCaseStudy($locale: String) {
    contentfulLockitronCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroDescription {
        internal {
          content
        }
      }
      knowingProjectTitle
      knowingProjectDescription {
        internal {
          content
        }
      }
      knowingProjectImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      newInterfaceTitle
      newInterfaceDescription {
        internal {
          content
        }
      }
      newInterfaceImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      newLookTitle
      newLookDescription {
        internal {
          content
        }
      }
      newLookImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      sharedAccessTitle
      sharedAccessDescription {
        internal {
          content
        }
      }
      sharedAccessImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      proximityTitle
      proximityDescription {
        internal {
          content
        }
      }
      proximityImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      wearablesTitle
      wearablesDescription {
        internal {
          content
        }
      }
      wearablesImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      realTimeTitle
      realTimeDescription {
        internal {
          content
        }
      }
      realTimeImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      controlTitle
      controlDescription {
        internal {
          content
        }
      }
      controlImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      controlImageMobile {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      testimonial {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      articleLink
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            src
            srcSet
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

LockitronCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default LockitronCaseStudy
